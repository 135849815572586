@import url("https://fonts.googleapis.com/css2?family=Cambay:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Cambay", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Thin scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  /* border-radius: 4px; Rounded corners of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #073A66; /* Color of the scrollbar handle on hover */
}

p {
  font-family: Poppins,sans-serif !important;
}